.project-wrapper {

  //Layout
  .project-info {
    @extend .grid-container;

    .project-intro {
      @include grid-column(12);

      @include grid-media(tablet) {
        @include grid-column(12);
      }

      @include grid-media(desktop) {
        @include grid-column-from-to(2,8);
      }
    }

    .project-details {
      @include grid-column(12);

      @include grid-media(desktop) {
        @include grid-column-from-to(10,11);
      }
    }
  }

  .project-details {
    .project-detail-wrapper {
      @extend .grid-container-nested-small;

      .project-detail-6 {
        @include grid-column(6);

        @include grid-media(phablet) {
          @include grid-column(3);
        }

        @include grid-media(tablet) {
          @include grid-column(2);
        }

        @include grid-media(desktop) {
          @include grid-column(6);
        }
      }

      .project-detail-12 {
        @include grid-column(6);

        @include grid-media(tablet) {
          @include grid-column(3);
        }

        @include grid-media(desktop) {
          @include grid-column(6);
        }
      }
    }
  }

  .project-text {
    @extend .grid-container;

    .project-text-wrapper {
      @include grid-column(12);

      @include grid-media(tablet) {
        @include grid-column(12);
      }

      @include grid-media(desktop) {
        @include grid-column-from-to(2,8);
      }
    }
  }

//   //Styles
  .image-wrapper {
    .image-wrapper img { width: 100%; }

    figcaption {
      margin-top: rem(12px);
      margin-bottom: rem(16px);
    }

    &.nudge-down-5 {
      @include grid-media(desktop) {
        margin-top: 5%;
      }
    }
  }

  .project-info {
    margin-bottom: rem(40px);
  }

  .project-text {
    @include grid-media(desktop) {
      margin-top: var(--gutter-row);
    }
  }
}
