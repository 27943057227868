.page-title-wrapper {
  @extend .grid-container;

  .page-title {
    @include grid-column(12);

    @include grid-media(tablet) {
      @include grid-column-from-to(1,10);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(2,7);
    }
  }

  .page-title {
    padding-bottom: 1rem;

    @include grid-media(desktop) {
      padding-bottom: 2rem;
    }
  }
}
