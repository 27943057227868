.main-header {
  //Prevent header from hiding
  &.header-prevent-hide {
    transform: translateY(0%) !important;
  }

  .header-content {

    .logo-nav-wrapper {
       @extend .grid-container;

      .logo-title-wrapper {
        @include grid-column(4);
        display: flex;

        @include grid-media(tablet) {
          @include grid-column(4);
        }
      }

      nav.navigation {
        @include grid-column(2);

        @include grid-media(tablet) {
          @include grid-column(8);
        }
      }
    }
  }

  .logo-nav-wrapper {
    a.accessibility-skip {
      @include visually-hidden;

      &:focus {
        top: 0;
        left: 0;
        right: 0;
        z-index: 200;
        width: auto;
        height: auto;
        padding: 0 8px;
        clip: auto;
        font-weight: bold;
        line-height: 49px;
        color: var(--color-text);
        text-decoration: none;
        background: black;
        outline: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .logo {
      transition: width 200ms ease,
                  margin-right 200ms ease;
      vertical-align: top;
      display: inline-block;
      margin-right: .6em;
      width: rem(48px);
      height: rem(48px);

      a {
        &:focus,
        &:active {
          background: inherit;
          border-top: none;
          border-right: none;
          border-bottom: 2px solid var(--color-text);
          border-left: none;
          opacity: 1;
          outline: 0;
        }

        svg {
          height: 100%;
          width: 100%;

          #logo {
            fill: var(--color-text);
          }
        }
      }
    }
  }

  .site-title {
    display: inline-block;
    transition: opacity $timing-interaction ease-in-out;
    height: 0;
    width: 0;
    overflow: hidden;
    text-decoration: none;
    display: flex;

    span {
      font-size: rem(16px);
      letter-spacing: .08rem;
      text-transform: uppercase;
      align-self: center;
    }
  }

  a.accessibility-skip {
    @include visually-hidden;

    &:focus {
      top: 0;
      text-align: center;
      left: calc(50% - (300px/2));
      z-index: 10000;
      width: 300px;
      height: auto;
      padding: 0 8px;
      clip: auto;
      font-weight: bold;
      line-height: 49px;
      color: black;
      text-decoration: none;
      background: white;
      outline: 0;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Sticky header behaviour
.main-header {
  will-change: transform,
               background,
               padding-top,
               padding-bottom;

  transition: transform 200ms ease,
              background 200ms ease,
              padding-top 300ms ease,
              padding-bottom 200ms ease;

  padding-top: rem(32px);
  padding-bottom: rem(20px);

  &.sticky-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s;
    z-index: 10000;

    .site-title {
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  &.sticky-header--hidden {
    transform: translate3d(0, -100%, 0); /* y value should be -1 * height */
  }

  &.sticky-header--fixed {
    background-color: var(--color-background);
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    position: fixed;
    border-bottom: 1px solid var(--color-line-subtle);

    .logo {
      width: rem(44px);
      height: rem(44px);
    }

    .site-title {
      opacity: 1;
      height: rem(44px);
      width: auto;
    }
  }

  &.sticky-header--hidden:after {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 100%;
    height: 4em;
  }

  &.sticky-header--hidden:hover {
    transform: translate3d(0, 0, 0);
  }
}
