/// Append pseudo-classes to a selector(s).
///
/// @argument {list | string} $inputs
///   A selector, or list of selectors, to apply the pseudo-class to.
///
/// @argument {pseudo-class} $pseudo [null]
///   The pseudo-class to be appended.
///
/// @return {list}
///
/// @access private

@function assign-inputs($inputs, $pseudo: null) {
  $list: ();

  @each $input in $inputs {
    $input: unquote($input);
    $input: if($pseudo, $input + ":" + $pseudo, $input);
    $list: append($list, $input, comma);
  }

  @return $list;
}

$text-inputs-list: (
  "[type='color']",
  "[type='date']",
  "[type='datetime']",
  "[type='datetime-local']",
  "[type='email']",
  "[type='month']",
  "[type='number']",
  "[type='password']",
  "[type='search']",
  "[type='tel']",
  "[type='text']",
  "[type='time']",
  "[type='url']",
  "[type='week']",
  "input:not([type])",
  "textarea",
);

$all-text-inputs: assign-inputs($text-inputs-list);
$all-text-inputs-active: assign-inputs($text-inputs-list, active);
$all-text-inputs-focus: assign-inputs($text-inputs-list, focus);
$all-text-inputs-hover: assign-inputs($text-inputs-list, hover);


form {
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  label {
    display: block;
    font-size: var(--font-size-form-label);
  }

  section {
    margin-bottom: rem(20px);
  }

  input:not([type='submit']),
  textarea {
    @include element-padding;

    width: 100%;
    margin-top: .3rem;
  }

  #{$all-text-inputs} {
    border: 0;
    border-bottom: rem(4px) solid white;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-bottom-color $timing-interaction;
    background-color: var(--color-form-background);
  }

  #{$all-text-inputs-hover} {
    border: 0;
    border-bottom: rem(4px) solid rgba(black, .2);
    transition: border-bottom-color $timing-interaction;
  }

  #{$all-text-inputs-focus},
  #{$all-text-inputs-active} {
    border: 0;
    border-bottom: rem(4px) solid var(--color-cta);
    transition: border-bottom-color $timing-interaction;
  }

  textarea {
    resize: vertical;
    min-height: 3.5rem;
  }
}
