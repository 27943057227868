.layout { padding-bottom: var(--gutter-row); }

.layout-1col,
.additional-content-wrapper {
  @extend .grid-container;

  .image-wrapper,
  .additional-content {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(12);
    }

    @include grid-media(desktop) {
      @include grid-column(12);
    }
  }
}

.layout-1col-narrow {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(12);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(2,11);
    }
  }
}

.layout-2col {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(6);
    }
  }
}

.layout-1col-10-centered {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(12);

    @include grid-media(tablet) {
      @include grid-column(12);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(2,11);
    }
  }
}

.layout-2col_mobile {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(3);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(6);
    }
  }
}

.layout-3col.layout-2col_mobile {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(3);

    @include grid-media(tablet) {
      @include grid-column(4);
    }

    @include grid-media(desktop) {
      @include grid-column(4);
    }
  }
}

.layout-3col {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(4);
    }

    @include grid-media(desktop) {
      @include grid-column(4);
    }
  }
}

.layout-4col {
  @extend .grid-container;

  .image-wrapper {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(3);
    }

    @include grid-media(desktop) {
      @include grid-column(3);
    }
  }
}

.layout-5col-7col {
  @extend .grid-container;

  .image-wrapper:first-child {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(5);
    }
  }

  .image-wrapper:last-child {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(7);
    }
  }
}

.layout-7col-5col {
  @extend .grid-container;

  .image-wrapper:first-child {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(7);
    }
  }

  .image-wrapper:last-child {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(6);
    }

    @include grid-media(desktop) {
      @include grid-column(5);
    }
  }
}
