::-moz-selection {
    color: var(--color-text);
    background: var(--color-highlight);
}

::selection {
    color: var(--color-text);
    background: var(--color-highlight);
}

html {
  box-sizing: border-box;
  background-color: var(--color-background);
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

figure {
  margin: 0;
}

img {
  display: block;
}

body {
  &.prevent-scroll-mobile {
    overflow: hidden;

    @include grid-media(tablet) {
      overflow: auto;
    }
  }
}

.global-wrapper {
  margin-top: 100px;
}

.main-content {
  min-height: calc(100vh - 268px);
  padding-top: 1rem;

  @include grid-media(tablet) {
    min-height: calc(100vh - 248px);
    padding-top: 2rem;
  }
  @include grid-media(desktop) {
    min-height: calc(100vh - 254px);
  }
}

.page-header-spacer {
  height: 2em;
}
