@mixin visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@function rem($size) {
  @return $size / 16px * 1rem;
}

@mixin element-padding {
  padding: var(--inner-padding-horizontal) var(--inner-padding);
}
