footer.main-footer {
  @extend .grid-container;
  color: #fff;
  padding-bottom: rem(32px);
  padding-top: rem(32px);

  @include grid-media(tablet) {
    padding-top: rem(64px);
  }

  .footer-license-wrapper {
    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(10);
    }
  }

  .made-by-wrapper {
    position: relative;

    @include grid-column(6);

    @include grid-media(tablet) {
      @include grid-column(2);
    }
  }

  a.license {
    text-decoration: none;
    color: var(--color-text);

    &:hover {
      border-bottom: 1px dashed;
    }
  }

  a.made-by {
    padding-bottom: 1em;
    border-bottom: 1px solid var(--color-line);
    text-decoration: none;
    text-transform: uppercase;
    word-wrap: break-word;
    letter-spacing: 0.1rem;
    color: var(--color-text);
    font-size: .6rem;

    @include grid-media(tablet) {
      position: absolute;
      width: 64px;
      transform: rotate(-90deg);
      right: -12px;
      bottom: 12px;
    }
  }
}
