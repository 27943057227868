img {
  //Fix blink opacity bug
  -webkit-transform: translateZ(0);
  width: calc(100% + .49px);

  max-width: 100%;
  height: auto;

  //image border
  &.light {
    border: 1px solid rgba(white, .08);
  }
}
