//Variables
$thumb-animation-time-in: $timing-interaction;
$thumb-animation-ease-in: ease-in-out;

$thumb-animation-time-out: $timing-interaction;
$thumb-animation-ease-out: ease-in-out;

//Layout
.projects-intro-wrapper {
  @extend .grid-container;

  .projects-intro {
    @include grid-column(6);

    @include grid-media(phablet) {
      @include grid-column-from-to(1,6);
    }

    @include grid-media(tablet) {
      @include grid-column-from-to(1,9);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(1,8);
    }
  }
}

.projects {
  @extend .grid-container;

  .project-listing {
    @include grid-column(6);

    @include grid-media(phablet) {
      @include grid-column(3);
    }

    @include grid-media(tablet) {
      @include grid-column(4);
    }

    @include grid-media(desktop) {
      @include grid-column(4);
    }
  }
}

//Styling
.projects-intro {
  position: relative;
  margin-bottom: 2.5rem;

  @include grid-media(phablet) {
    margin-bottom: 4rem;
  }

  @include grid-media(desktop) {
    margin-top: 5vh;
    margin-bottom: 4rem;
  }
}

.project-listing {

  .thumbnail-wrapper {
    a {
      outline: 0;
      text-decoration: none;

      img {
        width: 100%;
        height: auto;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        filter: grayscale(0%);

        // Animate filters
        transition-property: filter;
        transition-timing-function: $thumb-animation-ease-out;
        transition-duration: $thumb-animation-time-out;
      }

      h3 {
        display: block;
        color: var(--color-text);
        margin-top: .4em;
        font-size: var(--font-size-body);
      }

      .thumbnail-description-wrapper {
        display: block;
        position: relative;
        line-height: 1;
        background-color: transparent;
        overflow: hidden;
        width: 100%;
        padding-top: 75%;

        p.thumbnail-description {
          //Initially hidden
          opacity: 0;

          //animate out
          transition-property: opacity;
          transition-timing-function: $thumb-animation-ease-out;
          transition-duration: $thumb-animation-time-out;
          padding: .5rem 2rem;
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba(var(--color-highlight-rgb), var(--color-rgb-opacity-tint-dark));
          margin: 0;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          line-height: 1;

          //center text all ways (text is in span)
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          span.thumbnail-tag-media {
            display: block;
            position: relative;
            width: 100%;
            margin-top: 10px;
          }

          span {
            display: block;
            color: white;
            line-height: 1.4;
            font-size: 1.3rem;

            //offset for drop-in
            -webkit-transform-style: preserve-3d;
            transform: translate3d(0, 10px, 0);

            //animate drop-in out
            transition-property: transform;
            transition-timing-function: $thumb-animation-ease-out;
            transition-duration: $thumb-animation-time-out;
          }
        }
      }

      &:hover .thumbnail-description-wrapper p.thumbnail-description,
      &:focus .thumbnail-description-wrapper p.thumbnail-description {

        //semi-transparent background
        opacity: 1;

        //animate fade in
        transition-property: opacity;
        transition-timing-function: $thumb-animation-ease-in;
        transition-duration: $thumb-animation-time-in;

        span {
          //drop-in is reset back to original position
          transform: translate3d(0, 0, 0);

          //animate drop-in in
          transition-property: transform;
          transition-timing-function: $thumb-animation-ease-in;
          transition-duration: $thumb-animation-time-in;
        }
      }

      &:hover .thumbnail-description-wrapper img,
      &:focus .thumbnail-description-wrapper img {
        filter:
          grayscale(100%)
          //contrast(200%)
        ;
      }
    }
  }
}
