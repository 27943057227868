.contact-wrapper {

  .contact {
    @extend .grid-container;

    @include grid-media(tablet) {
      margin-top: 5vh
    }

    .contact-intro {
      @include grid-column(12);

      @include grid-media(tablet) {
        @include grid-column(5);
      }

      @include grid-media(desktop) {
        @include grid-column(5);
      }
    }

    .contact-form {
      @include grid-column(12);

      @include grid-media(tablet) {
        @include grid-column-from-to(7,12);
      }

      @include grid-media(desktop) {
        @include grid-column-from-to(7,12);
      }
    }
  }
}

