%button {
  @include element-padding;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--font-size-button);
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  transition:
    color $timing-interaction,
    background $timing-interaction;

  &:focus {
    outline:0;
  }

  &:active {
    transform: scale(0.99);
  }

  &:disabled,
  &.disabled{
    cursor: not-allowed;
    transform: scale(1);
  }
}

.button-secondary {
  @extend %button;
  border: 1px solid var(--color-cta);
  color: var(--color-cta);
  background: transparent;

  &:hover,
  &:focus {
    color: black;
    background: var(--color-cta);
  }

  &:active {
    opacity: .8;
    color: black;
    background: var(--color-cta);
  }

  &:disabled,
  &.disabled{
    opacity: .4;

    &:hover {
      color: var(--color-cta);
      background: transparent;
    }
  }
}

.button--small {
  padding: calc(var(--inner-padding-horizontal) - .3rem) calc(var(--inner-padding) - .3rem);
}
