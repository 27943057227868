main ul li {
  margin-bottom: .6rem;
}

.list--dashed {
  list-style-type: none;
  margin-left: rem(20px);

  > li {
    list-style-type: none;
    position: relative;
  }

  > li:before {
    content: "-";
    display: block;
    position: absolute;
    left: -#{rem(16px)};
    color: var(--color-line);
  }
}

dl.relational-list {
  dt.relational-list__title {}
  dd.relational-list__description {
    font-size: var(--font-size-body-small);
    opacity: var(--color-rgb-opacity-tint-mid);
    margin-bottom: 1rem;

    &.relational-list__description--period {
      margin-bottom: .2em;
      text-transform: uppercase;
      letter-spacing: var(--font-letter-spacing-subtle);
      font-size: var(--font-size-body-tiny);
    }
  }
}
