:root {
  --font-size-root: 16px;

  --line-height-regular: 1.5;
  --line-height-tight: 1.3;
  --line-height-tiny: 1.2;

  --font-size-xsmall: #{rem(12px)};
  --font-size-small: #{rem(14px)};
  --font-size-normal: #{rem(16px)};
  --font-size-large: #{rem(18px)};
  --font-size-xlarge: #{rem(20px)};
  --font-size-xxlarge: #{rem(24px)};
  --font-size-xxlarge-2: #{rem(28px)};
  --font-size-xxxlarge:#{rem(32px)};
  --font-size-xxxxlarge: #{rem(40px)};
  --font-size-xxxxxlarge: #{rem(56px)};
  --font-size-xxxxxxlarge: #{rem(72px)};

  --font-size-level-jumbo: var(--font-size-xxxxlarge);
  --font-size-level-1: var(--font-size-xxxxlarge);
  --font-size-level-2: var(--font-size-xxxlarge);
  --font-size-level-3: var(--font-size-xxlarge);
  --font-size-level-4: var(--font-size-large);
  --font-size-body: var(--font-size-large);
  --font-size-body-tiny: var(--font-size-xsmall);
  --font-size-body-small: var(--font-size-normal);
  --font-size-body-large: var(--font-size-large);
  --font-size-body-huge: var(--font-size-xxlarge);

  --font-size-button: var(--font-size-large);
  --font-size-form-label: var(--font-size-xlarge);
  --font-size-nav: var(--font-size-xlarge);

  --font-letter-spacing-subtle: .06rem;

  --paragraph-spacing: 1.3rem;

  --gutter: #{rem(24px)};
  --gutter-row: #{rem(24px)};
  --page-margin: #{rem(32px)};
  --inner-padding: #{rem(16px)};
  --inner-padding-horizontal: #{rem(10px)};

  --color-primary: 255,255,255;
  --color-secondary: 33,33,33;
  --color-tertiary: 0, 0, 255;
  --color-action: #65ff80;

  --color-highlight-rgb: var(--color-tertiary);
  --color-highlight: rgb(var(--color-highlight-rgb));

  --color-background: rgba(var(--color-secondary), 1);
  --color-background-rgb: var(--color-secondary);
  --color-line: rgba(var(--color-primary), var(--color-rgb-opacity-tint-mid));
  --color-line-subtle: rgba(var(--color-primary), var(--color-rgb-opacity-tint-light));
  --color-text: rgba(var(--color-primary),.95);
  --color-form-background: rgba(var(--color-primary),.95);
  --color-text-inverted: rgba(var(--color-secondary),.95);

  --color-cta: var(--color-action);

  --color-rgb-opacity-tint-light: .1;
  --color-rgb-opacity-tint-mid: .6;
  --color-rgb-opacity-tint-dark: .85;
}

@include grid-media(tablet) {
  :root {
    --line-height-tight: 1.24;
    --line-height-slight: 1.45;

    --font-size-level-jumbo: var(--font-size-xxxxxlarge);
    --font-size-level-1: var(--font-size-xxxxxlarge);
    --font-size-level-2: var(--font-size-xxxxlarge);
    --font-size-body: var(--font-size-xlarge);
    --font-size-body-large: var(--font-size-xxlarge);
    --font-size-body-huge: var(--font-size-xxxlarge);

    --page-margin: #{rem(64px)};
  }
}

@include grid-media(desktop) {
  :root {
    --font-size-root: 17px;
    --font-size-level-jumbo: var(--font-size-xxxxxxlarge);
    --font-size-body: var(--font-size-xlarge);
    --font-size-body-large: var(--font-size-xxlarge-2);
    --font-size-body-huge: var(--font-size-xxxxlarge);

    --gutter: #{rem(32px)};
    --gutter-row: #{rem(32px)};
  }
}

@include grid-media(largedesktop) {
  :root {
    --font-size-root: 20px;
    --page-margin: #{rem(128px)};
  }
}

/*
 * GEOMETRY
 */

//Base
$measure-xxxsmall: rem(4px);
$measure-xxsmall: rem(8px);
$measure-xsmall: rem(12px);
$measure-small: rem(20px);
$measure-normal: rem(28px);
$measure-medium: rem(32px);
$measure-large: rem(64px);

/*
 * GEOMETRY
 */

$timing-interaction: .2s;
