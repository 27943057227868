.fonts-intro {
  @extend .grid-container;

  .fonts-intro-info {
    @include grid-column-from-to(1,6);

    @include grid-media(tablet) {
      grid-row: 1;
      @include grid-column-from-to(9,12);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(1,3);

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .fonts-intro-description {
    @include grid-column-from-to(1,6);

    @include grid-media(tablet) {
      grid-row: 1;
      @include grid-column-from-to(1,7);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(5,12);
    }
  }
}

.font-listing {
  @extend .grid-container;

  .font-listing-details {
    @include grid-column-from-to(1,6);

    @include grid-media(tablet) {
      @include grid-column-from-to(1,12);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(1,4);
    }
  }

  .font-listing-preview {
    @include grid-column-from-to(1,6);

    @include grid-media(tablet) {
      @include grid-column-from-to(1,12);
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(5,12);
    }
  }
}

.font-listing {
  border-top: 1px solid var(--color-line);
  margin-top: var(--gutter-row);
  padding-top: 1rem;

  .font-listing-title {
    margin-bottom: rem(4px);

    a { text-decoration: none; }
  }

  .font-listing-styles {
      margin-bottom: rem(12px);
      text-transform: uppercase;
      letter-spacing: var(--font-letter-spacing-subtle);
      font-size: var(--font-size-body-small);
  }

  .font-listing-link-tertiary { font-size: var(--font-size-button); }

  .font-listing-vendor-link-separator {
    font-size: var(--font-size-button);
    margin: 0 rem(4px);
  }

  .font-listing-preview-overflow {
    overflow: hidden;
    position: relative;
    margin-top: rem(12px);
  }

  .font-listing-preview-overflow:before {
    content: '';
    display: block;
    position: absolute;
    right: -1px;
    z-index: 1;
    background: linear-gradient(to right, rgba(var(--color-background-rgb), 0) 0%,rgba(var(--color-background-rgb), 1) 100%);
    width: 2rem;
    height: 100%;
    @include grid-media(tablet) {
      display: none;
    }
  }

  .font-listing-preview img {
    max-width: initial;
    width: auto;
    height: rem(56px);

    @include grid-media(tablet) {
      max-width: 100%;
      height: auto;
      width: auto;
    }

    @include grid-media(desktop) {
    }

  }
}
