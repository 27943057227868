.about-wrapper {

  .about {
    @extend .grid-container;

    .about-intro-image {
      grid-row: 1;
      @include grid-column-from-to(1,3);

      @include grid-media(phablet) {
        @include grid-column-from-to(1,4);
      }

      @include grid-media(tablet) {
        @include grid-column-from-to(9,12);
      }

      @include grid-media(desktop) {
        @include grid-column-from-to(9,12);
      }
    }

    .about-intro-description {
      grid-row: 2;
      @include grid-column-from-to(1,6);

      @include grid-media(phablet) {
        @include grid-column-from-to(1,6);
      }

      @include grid-media(tablet) {
        grid-row: 1;
        @include grid-column-from-to(1,7);
      }

      @include grid-media(desktop) {
        @include grid-column-from-to(1,7);
      }
    }

    .about-attributes {
      grid-row: 3;
      @extend .grid-container-nested-big;
      @include grid-column(6);

      @include grid-media(tablet) {
        grid-row: 2;
        @include grid-column(12);
      }

      .about-tools,
      .about-skills,
      .about-education,
      .about-experience {
        @include grid-column(6);

        @include grid-media(phablet) {
          @include grid-column(6);
        }

        @include grid-media(tablet) {
          @include grid-column(6);
        }

        @include grid-media(desktop) {
          @include grid-column(3);
        }
      }
    }

    .about-site {
      grid-row: 4;
      @include grid-column(6);

      @include grid-media(tablet) {
        grid-row: 3;
        @include grid-column(12);
      }

      @include grid-media(desktop) {
        @include grid-column(6);
      }
    }
  }
}

.about-wrapper {
  .about {
    .about-section {
      border-top: 1px solid var(--color-line);
      margin-bottom: var(--gutter-row);
      padding-top: rem(8px);
    }

    .about-section--nested {
      margin-top: rem(40px);
    }

    .about-section-title {
      padding-bottom: rem(8px);
      margin-bottom: .5rem;
    }
  }
}
