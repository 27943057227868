.message-wrapper {
  @extend .grid-container;

  .message {
    @include grid-column(12);

    @include grid-media(tablet) {
      @include grid-column-from-to(3,10);
      margin-top: 5vh;
      margin-bottom: 8vh;
    }

    @include grid-media(desktop) {
      @include grid-column-from-to(4,9);
    }
  }
}

.message {
  h1 { margin-bottom: 1rem; }
  p { @extend .paragraph-large; }
}
