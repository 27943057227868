.global-wrapper > header nav {
  position: relative;
  z-index: 100;

  &:before {
    opacity: 0;
    content: '';
  }

  &.show {
    &:before {
      transition: opacity 150ms ease-in-out;
      content: '';
      position: fixed;
      width: 100%;
      height: 100vh;
      background: rgba(#000, 0.9);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 1;

      @include grid-media(tablet) {
        display: none;
      }
    }

    .mobile-nav {
      position: fixed;
      border: 1px solid white;
      color: white;

      &:hover,
      &:focus {
        background: rgba(white, 0.2);
      }

      &:active {
        background: rgba(white, 0.3);
      }
    }

    ul {
      display: block;
      opacity: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 80vh;
      max-height: calc(100vh - (100px + var(--page-margin)));
    }
  }

  a.mobile-nav {
    display: inline-block;
    float: right;
    right: var(--page-margin);
    padding: rem(10px) rem(20px);
    border: 1px solid var(--color-text);
    text-transform: uppercase;
    letter-spacing: var(--font-letter-spacing-subtle);
    color: var(--color-text);
    text-decoration: none;
    position: static;

    &:hover,
    &:focus {
      background: rgba(var(--color-text), 0.2);
      outline: 0;
    }

    &:active {
      background: rgba(var(--color-text), 0.3);
    }

    @include grid-media(tablet) {
      display: none;
    }
  }

  ul {
    max-height: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    transition: max-height 150ms ease-in-out;
    position: fixed;
    overflow: hidden;
    left: var(--page-margin);
    right: var(--page-margin);
    top: 100px;
    list-style-type: none;
    background: var(--color-text);
    background: black;
    border: 1px solid var(--color-text);
    font-size: var(--font-size-nav);
    letter-spacing: var(--font-letter-spacing-subtle);
    height: auto;

    li {
      display: block;
      border-bottom: 1px solid var(--color-text);

      &:last-of-type {
        border-bottom: 0;
      }

      a {
        text-decoration: none;
        color: var(--color-text);
        display: block;
        padding: var(--inner-padding);

        &:hover {
          background: rgba(var(--color-text), .2);
        }

        &.current {
          background: rgba(var(--color-text), .4);

          &:focus {
            color: gray;
            border-color: gray;
          }

          &:after {
            content: 'Current section';
            text-transform: uppercase;
            float: right;
            font-size: var(--font-size-body-small);
            margin-top: rem(4px);
          }
        }
      }
    }

    @include grid-media(tablet) {
      display: block;
      max-height: none;
      position: static;
      text-align: right;
      background: inherit;
      opacity: 1;
      border: 0;
      margin-top: rem(8px);

      li {
        display: inline-block;
        position: relative;
        padding-left: 1em;
        padding-bottom: rem(9px);
        border: 0;

        a {
          text-decoration: none;
          padding: 0 0 .02em 0;
          background-color: inherit;
          border-bottom: 1px solid transparent;
          opacity: 0.7;
          transition: all 100ms ease-in-out;

          &:hover,
          &:focus,
          &:active {
            background: inherit;
            border-top: none;
            border-right: none;
            border-bottom: 1px solid;
            border-left: none;
            opacity: 1;
            outline: 0;
          }

          &.current {
            border-top: none;
            border-right: none;
            border-bottom: 1px solid;
            border-left: none;
            opacity: 1;
            background: inherit;

            &:after {
              @include visually-hidden;
            }
          }
        }
      }
    }
  }
}
