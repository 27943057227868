a {
  color: var(--color-text);
}

main p a,
main li a,
.formatted-link {
  text-decoration: none;
  color: var(--color-cta);
  border-bottom: rem(1.2px) dashed;
  border-bottom-color: transparent;

  &:hover {
    border-bottom-color: var(--color-cta);
  }
}
