$font-family-primary: 'DM Sans', -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
$font-family-secondary: 'DM Serif Display', serif;

html {
  font-family: sans-serif;
  font-family: $font-family-primary;
  font-size: var(--font-size-root);
  color: var(--color-text);
  line-height: var(--line-height-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6, p, ul, li, dl, dt, dd {
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.type-level-jumbo {
  font-family: $font-family-secondary;
  font-size: var(--font-size-level-jumbo);
  line-height: var(--line-height-tiny);
}

.type-level-1 {
  font-family: $font-family-secondary;
  font-size: var(--font-size-level-1);
  line-height: var(--line-height-tight);
}

.type-level-2 {
  font-family: $font-family-secondary;
  font-size: var(--font-size-level-2);
  line-height: var(--line-height-tiny);
}

.type-level-3 {
  font-size: var(--font-size-level-3);
  line-height: var(--line-height-tight);
}

.type-level-4 {
  font-size: var(--font-size-level-4);
}

.title-label {
  opacity: var(--color-rgb-opacity-tint-mid);
  color: var(--color-text);
  font-size: var(--font-size-body-small);
  text-transform: uppercase;
  letter-spacing: var(--font-letter-spacing-subtle);
  margin-bottom: rem(4px);
}

.paragraph-normal,
.paragraph-normal p {
  margin-bottom: var(--paragraph-spacing);
  font-size: var(--font-size-body);
}

.paragraph-small,
.paragraph-small p {
  margin-bottom: var(--paragraph-spacing);
  font-size: var(--font-size-body-small);
}

.paragraph-large,
.paragraph-large p {
  margin-bottom: var(--paragraph-spacing);
  font-size: var(--font-size-body-large);

  a { border-bottom-width: 1.5px }
}

.paragraph-huge,
.paragraph-huge p {
  margin-bottom: var(--paragraph-spacing);
  font-size: var(--font-size-body-huge);
  line-height: var(--line-height-slight);

  a { border-bottom-width: 2px }
}

small,
figcaption {
  font-size: var(--font-size-body-tiny);
  line-height: var(--line-height-tiny);
}
